<template>
  <div
    class="price-info"
  >
    <div class="price-info__current">
      <span
        class="price-info__value"
        v-html="value.currentValue"
      />
      <span class="price-info__currency">
        {{ value.currency }}
      </span>
    </div>

    <span
      class="price-info__unit-text"
      v-html="getUnitText()"
    />

    <div class="price-info__unit">
      <span class="price-info__unit-price">
        <span
          v-if="value.unitPrice"
          v-html="getUnitPrice(value)"
        />
        <template v-if="alcohol">
          <span>
            &nbsp;- {{ $t('productlist.priceinfo.alcohol.abbrivation') }} {{ alcohol }}
          </span>
        </template>
      </span>
    </div>

    <div
      v-if="showTax"
      class="price-info__tax"
      v-html="$t(showShippingIncluded ? messageKey.deliveryInclShip : messageKey.deliveryInclVat, [shippingInfoUrl])"
    />
  </div>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type Price from '../../../bps/types/Price';
  import {Injectable} from '../../../bps/utilities/js/diContainer/diContainer';

  @Injectable('PriceInfo')
  @Options({
    name: 'price-info'
  })
  export default class PriceInfo extends Vue {
    @Prop({required: true})
    value: Price;

    @Prop({default: false})
    showTax: boolean;

    @Prop({required: false, default: null})
    alcohol: string;

    @Prop({default: false})
    showPackageSize: boolean;

    @Prop({default: false})
    showShippingIncluded: boolean;

    @Prop()
    shippingInfoUrl: string;

    messageKey: {[key: string]: any} = {
      deliveryInclShip: 'price.incl_vat_incl_ship',
      deliveryInclVat: 'price.incl_vat',
    };

    mounted() {
      if (window.__productConfig && window.__productConfig.messageKey) {
        this.messageKey = window.__productConfig.messageKey;
      }
    }

    getUnitText() {
      let result = this.$t(`product.price.volumeUnit.${this.value.volumeUnit}`);
      if (this.value.unitPrice) {
        if (this.value.packageSize && this.showPackageSize) {
          result += ' (' + this.value.packageSize + ')';
        }
        result += '<span class="comma">,</span>';
      }
      return result;
    }

    getUnitPrice(value) {
      return value.unit ? `${value.unitPrice}/${value.unit}` : value.unitPrice;
    }
  }
</script>
