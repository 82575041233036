import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "price-info" }
const _hoisted_2 = { class: "price-info__current" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "price-info__currency" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "price-info__unit" }
const _hoisted_7 = { class: "price-info__unit-price" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "price-info__value",
        innerHTML: _ctx.value.currentValue
      }, null, 8 /* PROPS */, _hoisted_3),
      _createTextVNode(),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.value.currency), 1 /* TEXT */)
    ]),
    _createTextVNode(),
    _createElementVNode("span", {
      class: "price-info__unit-text",
      innerHTML: _ctx.getUnitText()
    }, null, 8 /* PROPS */, _hoisted_5),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, [
        (_ctx.value.unitPrice)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              innerHTML: _ctx.getUnitPrice(_ctx.value)
            }, null, 8 /* PROPS */, _hoisted_8))
          : _createCommentVNode("v-if", true),
        _createTextVNode(),
        (_ctx.alcohol)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, "\n             - " + _toDisplayString(_ctx.$t('productlist.priceinfo.alcohol.abbrivation')) + " " + _toDisplayString(_ctx.alcohol), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createTextVNode(),
    (_ctx.showTax)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "price-info__tax",
          innerHTML: _ctx.$t(_ctx.showShippingIncluded ? _ctx.messageKey.deliveryInclShip : _ctx.messageKey.deliveryInclVat, [_ctx.shippingInfoUrl])
        }, null, 8 /* PROPS */, _hoisted_10))
      : _createCommentVNode("v-if", true)
  ]))
}