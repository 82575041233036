import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "product-box-wide__segment-center" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "product-box-wide__header-text product-box__header-text" }
const _hoisted_4 = {
  key: 0,
  class: "product-box-wide__vintage product-box__vintage"
}
const _hoisted_5 = { class: "product-box-wide__name product-box__name" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "product-box-wide__rating-container"
}
const _hoisted_8 = { class: "product-box-wide__bottle-amount" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ratings = _resolveComponent("ratings")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "product-box-wide__header product-box__segment-header",
      href: _ctx.productBoxData.url
    }, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.productBoxData.articlesInSet && _ctx.productBoxData.vintage)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.productBoxData.vintage), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createTextVNode(),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.productBoxData.name), 1 /* TEXT */),
        _createTextVNode(),
        (_ctx.productBoxData.description2)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "product-box-wide__short-description product-box__short-description",
              innerHTML: _ctx.productBoxData.description2
            }, null, 8 /* PROPS */, _hoisted_6))
          : _createCommentVNode("v-if", true)
      ])
    ], 8 /* PROPS */, _hoisted_2),
    _createTextVNode(),
    ((_ctx.productBoxData.numberOfBottlesInSet && _ctx.productBoxData.numberOfBottlesInSet >= 2)
        || _ctx.productBoxData.ratings > _ctx.minimumRating || _ctx.productBoxData.inBasket)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, [
            (_ctx.productBoxData.numberOfBottlesInSet && _ctx.productBoxData.numberOfBottlesInSet >= 2)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.productBoxData.numberOfBottlesInSet) + " " + _toDisplayString(_ctx.$t('volumeUnit.BOTTLE.other')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          _createTextVNode(),
          (_ctx.productBoxData.ratings > _ctx.minimumRating && !_ctx.productBoxData.inBasket)
            ? (_openBlock(), _createBlock(_component_ratings, {
                key: 0,
                class: "product-box-wide__ratings",
                rating: _ctx.productBoxData.ratings,
                type: "disabled"
              }, null, 8 /* PROPS */, ["rating"]))
            : _createCommentVNode("v-if", true),
          _createTextVNode(),
          (_ctx.productBoxData.inBasket)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "product-box__status product-box__status--basket product-box-wide__status",
                href: _ctx.basketUrl
              }, _toDisplayString(_ctx.$t('ads.ads_top_info.article_in_basket')), 9 /* TEXT, PROPS */, _hoisted_10))
            : _createCommentVNode("v-if", true),
          _createTextVNode(),
          (_ctx.state === 'successorInBasket')
            ? _withDirectives((_openBlock(), _createElementBlock("a", {
                key: 2,
                class: "product-box__status product-box__status--basket",
                href: _ctx.basketUrl
              }, null, 8 /* PROPS */, _hoisted_11)), [
                [_directive_t, 'ads.ads_top_info.successor_in_basket']
              ])
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    _createElementVNode("div", {
      class: "product-box-wide__long-description",
      innerHTML: _ctx.descriptionExcerpt
    }, null, 8 /* PROPS */, _hoisted_12)
  ]))
}