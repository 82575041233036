import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "product-box__header-text" }
const _hoisted_2 = {
  key: 0,
  class: "product-box__vintage"
}
const _hoisted_3 = { class: "product-box__name" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "product-box__short-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productLink ? 'a' : 'div'), {
    href: _ctx.productLink,
    class: "product-box__segment-header"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.productBoxData.vintage)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.productBoxData.vintage), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createTextVNode(),
        _createElementVNode("span", _hoisted_3, [
          (_ctx.numberOfBottlesInSet)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.numberOfBottlesInSet) + " x\n        ", 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createTextVNode(" " + _toDisplayString(_ctx.productBoxData.description1), 1 /* TEXT */)
        ]),
        _createTextVNode(),
        (_ctx.productBoxData.description2)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.productBoxData.description2), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      _createTextVNode(),
      _createElementVNode("span", {
        class: _normalizeClass(["product-box__button-wishlist", {
        'product-box__button-wishlist--filled': _ctx.isWishlistEnabled && _ctx.productBoxData.inWishlist,
        'product-box__button-wishlist--remove': !_ctx.isWishlistEnabled,
        'product-box__button-wishlist--loading': _ctx.state === 'loadingWishlist',
      }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('wishlistClicked')), ["prevent"]))
      }, null, 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["href"]))
}