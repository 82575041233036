import JsComponent from '../../../bps/components/jsComponent/jsComponent.js';
import pigeon from '../../../bps/utilities/js/pigeon/pigeon';

class CheckoutStepDelivery extends JsComponent {
  constructor(mainElement, name = 'checkout-step-delivery') {
    super(mainElement, name);
    pigeon.subscribe('deliveryOptionsForm:optionChanged', (data) => {
      if (
        data &&
        data.option &&
        data.option === 'PERSONAL_PICKUP' &&
        data.component &&
        data.component.model &&
        data.component.model.deliveryOption &&
        data.component.initialValues &&
        data.component.initialValues.deliveryOption
      ) {
        pigeon.publish(
          'lightbox:showUrl',
          '/lightboxes/personal-pickup'
        );
        document.getElementById('radio-deliveryOptionsViewmodel.deliveryOption-STANDARD').checked = true;
        data.component.model.deliveryOption = data.component.initialValues.deliveryOption;
      }
    });
  }
}

export default CheckoutStepDelivery;
