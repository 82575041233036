import diContainer from '../../bps/utilities/js/diContainer/diContainer';

// Objects needed for injection into generic components
import {productBoxSettings} from './productBox/productBoxSettings';
import {productBoxWideSettings} from './productBoxWide/productBoxWideSettings';
import {productDetailsSettings} from './productDetails/productDetailsSettings';
import {productContentBlockSettings} from '../../bps/components/productContentBlock/productContentBlockSettings';
import {productsOrderTableSettings} from './productsOrderTable/productsOrderTableSettings';
import {priceHelper} from '../utilities/js/priceHelper/priceHelper';
import {videoPlayerSettings} from './videoPlayer/videoPlayerSettings';
import {productFeaturesSettings} from './productFeatures/productFeaturesSettings';
import {accountRecurringOrderItemSettings} from '../../bps/components/accountRecurringOrderItem/accountRecurringOrderItemSettings';
import {formSettings} from '../../bps/components/form/formSettings';
import {ValidationFieldConfig, ValidationValues} from '../utilities/js/validation/ValidationFieldConfig';
//
import {TransformerPipeline} from '../../bps/utilities/js/imageUrlTransformer/TransformerPipeline';
import {transformSpaces} from '../../bps/utilities/js/imageUrlTransformer/urlTransformers';

const imageUrlTransformer = new TransformerPipeline();
imageUrlTransformer.add(transformSpaces);

diContainer.add({
  productBoxSettings,
  productBoxWideSettings,
  productDetailsSettings,
  productContentBlockSettings,
  productsOrderTableSettings,
  videoPlayerSettings,
  priceHelper,
  productFeaturesSettings,
  accountRecurringOrderItemSettings,
  formSettings,
  ValidationFieldConfig,
  ValidationValues,
  validationRules: () => import(/* webpackChunkName: "form" */ '../utilities/js/validation/validationRules'),
  imageUrlTransformer,
});
