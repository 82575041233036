import {ValidationFieldConfig, ValidationValues} from  '../../../../bps/utilities/js/validation/ValidationFieldConfig';
import {minAge, regex} from '../../../../bps/utilities/js/validation/validators';
import Personnummer from 'personnummer';


ValidationValues.minAgeValue = 24;

ValidationFieldConfig.birthdate.minAge = minAge(ValidationValues.minAgeValue);
delete(ValidationFieldConfig.street.packageStore);
delete(ValidationFieldConfig.careOf.packageStore);

Object.assign(ValidationFieldConfig, {
  ssn: {
    ssn: Personnummer.valid,
    regex: regex(/^[0-9]{6}-[0-9]{4}$/),
  }
})


type ValidationFieldConfigKey = keyof typeof ValidationFieldConfig;
export {ValidationFieldConfig, ValidationFieldConfigKey, ValidationValues}
